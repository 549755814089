<template>
  <div class="navigationSeventh">
    <div class="navigationSevenths" />
    <div :class="srcs == 2 ? 'navigationSeventh boxs' : 'navigationSeventh boxs'">
      <!-- 导航栏 -->
      <!-- <div class="nav_til">
        <div class="welcome">
          <i class="iconfont icon-xueli" />提升学历，助力成就梦想
        </div>
        <div class="mobile">
          <i class="iconfont icon-kefurexian" />
          <div>客服热线： {{ domainInfo.mobile }}</div>
        </div>
      </div> -->
      <div class="nav_bot">
        <div class="nav_bot_msg">
          <div class="logo">
            <img class="logo_icon" :src="domainInfo.logo" fit="contain" @click="shouy">
            <!-- <el-divider direction="vertical" />
            <i class="iconfont icon-Frame1" />
            <div class="site">{{ domainInfo.name }}</div> -->
            <!-- /* 菜单导航栏 */ -->
            <div class="nav m-l-24">
              <el-menu menu-trigger="click" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="/">
                  <div class="menu-box">首页</div>
                </el-menu-item>

                <!-- <el-menu-item
                  v-for="(item, index) in majorList"
                  :key="index"
                  :index="`/partnerPage/typeClass?kind=${item.pKind}&school=${item.pParentid}`"
                >
                  <div class="menu-box">
                    {{ item.pName }}
                  </div>
                </el-menu-item> -->

                <!-- <el-menu-item index="/partnerPage/publicClass"
                  ><div class="menu-box">公开课</div></el-menu-item
                > -->
                <!-- <el-menu-item index="/partnerPage/typeclass"
                  ><div class="menu-box">课程分类</div></el-menu-item
                > -->
                <el-menu-item index="/partnerPage/VIPClass">
                  <div class="menu-box">辅导班型</div>
                </el-menu-item>
                <el-menu-item index="/facultyPartner">
                  <div class="menu-box">师资力量</div>
                </el-menu-item>
                <!-- <el-menu-item index="/mall"
                  ><div class="menu-box">教辅商城</div></el-menu-item
                > -->
                <!-- <el-menu-item index="/facultysixth"><div class="menu-box">师资力量</div></el-menu-item> -->
                <el-menu-item :class="activeIndex == '/partnerPage/myexamination' ||
                  activeIndex == '/partnerPage/mydata' ||
                  activeIndex == '/partnerPage/myTask' ||
                  activeIndex == '/partnerPage/myRoom' ||
                  activeIndex == '/partnerPage/study'
                  ? 'is-active'
                  : ''
                  " index="/partnerPage/mycurriculum">
                  <div class="menu-box">学习中心</div>
                </el-menu-item>
                <el-menu-item :class="activeIndex == '/partnerPage/my/mineOrder' ||
                  activeIndex == '/partnerPage/my/mineCoupon' ||
                  activeIndex == '/partnerPage/my/mineExchange' ||
                  activeIndex == '/partnerPage/my/myCard' ||
                  activeIndex == '/partnerPage/my/feedback' ||
                  activeIndex == '/partnerPage/my/accountSetting' ||
                  activeIndex == '/partnerPage/my/mineNews' ||
                  activeIndex == '/partnerPage/my/collect'
                  ? 'is-active'
                  : ''
                  " index="/partnerPage/my/myInfo">
                  <div class="menu-box">个人中心</div>
                </el-menu-item>
              </el-menu>
              <!-- <div @click="open" class="menubox">高校伙伴</div> -->
            </div>
          </div>

          <!-- /* 搜索 */ -->
          <!--       <div class="sear">
          <el-input
            placeholder="输入关键字搜索"
            v-model="input"
            @keyup.enter="searchClick"
            class="input-with-select"
          >
            <el-button slot="append" @click="searchClick" icon="el-icon-search"> </el-button>
          </el-input>
        </div> -->
          <div class="flex-c">
            <!-- /* 消息 */ -->
            <div v-if="userInfo" class="tilmessage" @click="gopage(`/partnerPage/my/mineNews`)">
              <img src="@/assets/img/homesixth/Group 3854.png" />
              <div class="tilmessageTitle">消息</div>
              <div v-if="noRead > 0" class="xx">({{ noRead }})</div>
              <el-divider direction="vertical" />
            </div>
            <!-- 未登录 -->
            <div v-if="!tok" class="flex-c">
              <!-- <img
                class="userIcon"
                src="@/assets/img/homeSeventh/userIcon.png"
                alt=""
                @click="goNext(`/partnerPage/my/myInfo`)"
              /> -->
              <div class="goin" @click="mss(1)">登录</div>
              <div class="colleges-learn" @click="mss(2)">免费注册</div>
            </div>
            <!-- 已登录 -->
            <div v-if="tok" class="headersRight">
              <div class="headersRights">
                <img class="userIcon" :src="userInfos.img" alt="" @click="goNext(`/partnerPage/my/myInfo`)">
                <el-dropdown click="gouOut" trigger="click">
                  <div class="loginOut" @click="clogin">退出</div>
                  <!-- <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <div class="nickName">{{ userInfos.nickName }}</div>
                      <div class="loginOut" @click="clogin">退出</div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div @click="handleSelect(`/partnerPage/my/myInfo`)">
                        个人中心
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu> -->
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 登录弹框 -->
      <el-dialog class="loginDialog" title="" :visible.sync="showLogin" :before-close="handleClose">
        <new-login v-if="showLogin" :key="newloginKey" @toHome="toHome" @closes="closes" @toRegister="toRegister"
          @showForgot="showForgot" />
      </el-dialog>
      <!-- 注册弹框 -->
      <el-dialog :close-on-click-modal="false" class="loginDialog" title="" :visible.sync="showRegister"
        :before-close="handleCloseRegister">
        <new-register v-if="showRegister" :key="registerKey" @closess="closess" @handleShowLogin="handleShowLogin" />
      </el-dialog>
      <!-- 忘记密码弹框 -->
      <el-dialog class="loginDialog" title="" :close-on-click-modal="false" :visible.sync="showForgotDialog"
        :before-close="handleCloseForgot">
        <for-got :key="forgotKey" @dealForgot="dealForgot" @closes="closes" />
      </el-dialog>
      <!-- 赠送优惠券提示弹窗 -->
      <coupon-tips :coupon-tips-visible="couponTipsVisible" :oupon-list="ouponList" @couponTipsClose="couponTipsClose" />
    </div>
  </div>
</template>
<script>
import { loginOut } from '@/api/user'
import { Know } from '@/api/know'
import couponTips from './couponTips.vue'
const know = new Know()
import Bus from '@/api/bus'
import Vue from 'vue'
import { getMYTenantKindZyList } from '@/api/home'
import {
  setReady,
  getToken,
  getInfo,
  getDomainLogo,
  getDomain
} from '@/api/cookies'
import newLogin from '../views/login/partnerLogin.vue'
import newRegister from '../views/login/partnerRegister.vue'
import forGot from '../views/login/partnerforget.vue'
export default {
  components: { newLogin, newRegister, forGot, couponTips },
  data() {
    return {
      userInfo: null,
      couponTipsVisible: false,
      forgotKey: 0,
      newloginKey: 0,
      registerKey: 0,
      showForgotDialog: false,
      mustBack: null,
      showRegister: false,
      showLogin: false,
      activeIndex: '/',
      // 导航栏选中颜色样式
      // 输入框
      input: '',
      // 基本资料
      userInfos: {},
      domainInfo: {},
      // 是否有token
      tok: false,
      token: '',
      noRead: 0,
      userId: null,
      logo: '',
      data: {
        name: '我的题库',
        path: '/all/myexamination',
        chil: [
          { name: '学习资料', path: '/all/mydata' },
          { name: '我的题库', path: '/all/myexamination' },
          { name: '我的作业', path: '/all/myTask' }
        ]
      },
      loginInfo: null,
      ouponList: [],
      majorList: [],
      kindList: [],
      srcs: 2
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      this.activeIndex = newVal.path
      this.userId = localStorage.getItem('userId')
      if (oldVal.path == '/login' || newVal.path == '/login') {
        this.token = getToken()
        if (this.token && this.token != '') {
          this.tok = true
        } else {
          this.tok = false
        }
      }
      if (oldVal.path == '/search') {
        this.input = ''
      }
      /*   { name: "学习资料", path: "/all/mydata" },
          { name: "我的题库", path: "/all/myexamination" },
          { name: "我的作业", path: "/all/myTask" }, */
      if (newVal.path == '/all/mydata') {
        this.data.name = '学习资料'
        this.data.path = '/all/mydata'
      } else if (newVal.path == '/all/myexamination') {
        this.data.name = '我的题库'
        this.data.path = '/all/myexamination'
      } else if (newVal.path == '/all/myTask') {
        this.data.name = '我的作业'
        this.data.path = '/all/myTask'
      }
      this.userInfos = getInfo()
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        this.noRead = res.msg
      })
    }
  },
  async created() {
    this.userInfo = getInfo()
    Bus.$on('readChane', () => {
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        setReady(res.msg)
        this.noRead = res.msg
      })
    })
    Bus.$on('handleLogin', (item) => {
      if (item) {
        this.cacheVal = item
      }
      this.showLogin = true
    })
    Bus.$on('toLoginPage', (bol) => {
      this.showLogin = bol
    })
    Bus.$on('handleRegister', (bol) => {
      this.showRegister = bol
    })
    this.getKind()
    this.cacheVal = null
    this.activeIndex = this.$route.path
    this.domainInfo = getDomain()
    this.logo = getDomainLogo()
    this.userInfos = ''
    this.userId = localStorage.getItem('userId')
    this.token = getToken()
    this.userInfos = getInfo()
    know.getUnreadInformList(this.userId, undefined).then((res) => {
      setReady(res.msg)
      this.noRead = res.msg
    })

    if (this.token && this.token != '') {
      this.tok = true
    } else {
      this.tok = false
    }
    this.loginInfo = sessionStorage.getItem('loginInfo')
    if (this.loginInfo == 1 && getInfo()) {
      /* this.getNewUserSendComponList() */
    }
    this.$forceUpdate()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // getNewUserSendComponList() {
    //   const data = {
    //     userid: getInfo().id
    //   }
    //   getNewUserSendComponList(data).then((res) => {
    //     if (res.code == 0 && res.msg.length > 0) {
    //       this.couponTipsVisible = true
    //     }
    //   })
    // },
    async getKind() {
      await getMYTenantKindZyList().then((res) => {
        if (res.code == 0) {
          this.kindList = res.data
          const oldList = []
          // console.log(this.zhuanList)
          this.kindList.filter((item) => {
            if (item.pKind == 3) {
              item.num = 1
              item.pName = '统招专升本'
              item.path = ``
              oldList.push(item)
            }
            if (item.pKind == 1) {
              item.num = 2
              console.log(item)
              item.pName = '自考专升本'
              oldList.push(item)
            }
            if (item.pKind == 9) {
              item.num = 3
              item.pName = '普通考研'
              oldList.push(item)
            }
          })
          const len = oldList.length
          for (let i = 0; i < len; i++) {
            for (let j = 0; j < i; j++) {
              if (oldList[j].num > oldList[i].num) {
                const temp = oldList[j]
                oldList[j] = oldList[i]
                oldList[i] = temp
              }
            }
          }
          this.majorList = oldList
        } else {
          this.majorList = []
        }
      })
    },
    /*  openCouponTips() {
      this.getNewUserSendComponList()
      this.couponTipsVisible = true

    }, */
    couponTipsClose() {
      this.couponTipsVisible = false
    },
    open() {
      window.open('http://gxhh.juxueu.com', '_blank')
    },

    handleCloseForgot() {
      this.forgotKey += 1
      this.showForgotDialog = false
    },
    handleCloseRegister() {
      this.registerKey += 1
      this.showRegister = false
    },
    mss(index) {
      if (index == 1) {
        this.cacheVal = ''
        this.showLogin = true
        // this.$router.push("/login");
      }
      if (index == 2) {
        this.showRegister = true
        // this.$router.push("/register");
      }
    },
    dealForgot() {
      this.showForgotDialog = false
      this.showLogin = true
    },
    showForgot() {
      this.showForgotDialog = true
      this.showLogin = false
    },
    handleClose() {
      this.newloginKey += 1
      if (this.cacheVal == 'mustBack') {
        this.$router.go(-1)
      }
      this.showLogin = false
    },
    closes() {
      console.log(111111111)
      this.showLogin = false
      this.showForgotDialog = false

    },
    closess() {
      console.log(111111111)
      this.showRegister = false
    },
    toRegister() {
      this.cacheVal = ''
      this.showLogin = false
      this.showRegister = true
    },
    toHome() {
      this.cacheVal = ''
      this.showLogin = false
    },
    handleShowLogin(bol) {
      this.showLogin = bol
      this.showRegister = false
    },
    gopage(path) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      this.$router.push(path)
    },
    /* 菜单切换 */
    handleSelect(key, keyPath) {
      const userInfo = getInfo()
      for (const item of this.data.chil) {
        if (key == item.path) {
          this.data.name = item.name
          this.data.path = item.path
          break
        }
      }
      if (
        !userInfo &&
        (key == '/partnerPage/my/myInfo' || key == '/partnerPage/mycurriculum')
      ) {
        Vue.prototype.goLoginView(false)
        return
      }

      const routeUrl = this.$router.resolve({
        path: key
      })
      window.open(key, '_blank')
    },
    // 点击logo图片跳转首页
    shouy() {
      this.$router.push({ path: '/' })
    },
    // 点击退出登录
    async clogin() {
      loginOut()
      localStorage.clear()

      this.$router.replace(`/`)
      window.location.reload()
    },
    searchClick() {
      if (location.pathname == '/search') {
        Bus.$emit('search', this.input)
      } else {
        this.$router.replace({ name: '搜索', params: { search: this.input } })
      }
    },
    handleScroll() {
      var yheight1 = window.pageYOffset
      if (yheight1 > 0) {
        this.srcs = 1
      } else {
        this.srcs = 2
      }
    }
  }
}
</script>
<style lang="less">
.el-dropdown-menu {
  z-index: 99999 !important;

  .el-dropdown-menu__item {
    padding: 0px !important;

    div {
      width: 100%;
      padding: 0px 20px;
      text-align: center;
    }
  }
}

.el-menu--popup {
  .el-menu-item {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171920;
    // line-height: 90px;
    border: none !important;
    cursor: pointer;
  }

  .is-active {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff4027 !important;
    border: none !important;
  }
}

.el-menu {
  background-color: transparent !important;
}
</style>
<style lang="less" scoped>
.navigationSeventh {
  width: 100%;

  // background: #fff;
  position: relative;

  // 导航栏
  .nav_til {
    width: 100%;
    padding: 0px calc(50% - 600px);
    display: flex;
    justify-content: space-between;
    height: 36px;
    background: rgba(245, 246, 249, 0.6);
    line-height: 36px;

    .welcome {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 40px;

      .iconfont {
        color: #666666;
        font-size: 12px;
        margin-right: 4px;
      }
    }

    .mobile {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 12px;
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 16px;
        margin-right: 8px;
        flex-shrink: 0;
        color: #fd821a;
      }
    }
  }

  .nav_bot {
    width: 100%;
    height: 70px;
    box-shadow: 0px 2px 4px 1px rgba(204, 204, 204, 0.2);
    border-radius: 0px 0px 0px 0px;

    .nav_bot_msg {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;

        .logo_icon {
          // width: 101px;
          height: 38px;
          cursor: pointer;
          flex-shrink: 0;
        }

        .el-divider {
          margin: auto 28px auto 37px;
          width: 1px;
          height: 16px;
          background: #dddddd;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .iconfont {
          color: #0270E8;
          flex-shrink: 0;
          font-size: 14px;
          margin-right: 8px;
        }

        .site {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          flex-shrink: 0;
          line-height: 14px;
        }
      }

      /* 菜单导航栏 */
      .nav {
        display: flex;
        flex-shrink: 0;
        border-radius: 0px 0px 0px 0px;

        .menubox {
          height: 32px;
          line-height: 34px;
          color: #333;
          margin-left: 15px;
          border: none;
          cursor: pointer;
        }

        /deep/ .el-menu-demo {
          // margin: 0 auto;
          margin-left: 44px;
          border: none;

          .el-menu-item {
            height: 32px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            padding: 0 14px;
            // position: relative;
          }

          .is-active {
            color: #0270E8 !important;
            border: none;

            .menu-box {
              position: relative;

              &::before {
                width: 12px;
                height: 4px;
                background: #0270E8;
                border-radius: 2px 2px 2px 2px;
                opacity: 1;
                display: table;
                content: '';
                position: absolute;
                margin: 35px calc(50% - 6px) 0px;
              }
            }
          }
        }
      }

      /* 搜索 */
      .sear {
        /deep/.input-with-select {
          width: 200px;
          height: 40px;
          overflow: hidden;

          .el-input__inner {
            height: 38px;
            border: none;
            background: #ffffff;
            border-radius: 8px 0px 0px 8px;
            opacity: 1;
            border: 1px solid #4a6af0;
            margin-top: 1px;
            border-right: none;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }

          .el-input-group__append {
            width: 40px;
            height: 100%;
            background: #4a6af0;
            border-radius: 0px 8px 8px 0px;
            opacity: 1;
            text-align: center;

            .el-icon-search {
              font-size: 16px;
              color: #ffffff;
            }

            .el-button {
              padding: 0px !important;
            }
          }
        }
      }

      /* 消息 */
      .tilmessage {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .tilmessageTitle {
          margin-left: 4px;
        }

        .el-divider {
          width: 1px;
          height: 8px;
          background: #cccccc;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          margin: auto 12px;
        }
      }

      /* 用户信息 */
      .userIcon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 12px;
      }

      .goin {
        width: 32px;
        height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-right: 20px;
        cursor: pointer;
      }

      .colleges-learn {
        width: 106px;
        height: 36px;
        background: linear-gradient(90deg, #88befc 0%, #1c81f5 100%);
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        border: 0;
      }

      .nickName {
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        cursor: pointer;
      }
    }
  }
}

.el-dropdown-item {
  padding: 0px;
}

.xx {
  color: #ff5206;
}

/deep/.el-dialog {
  width: 480px;
  border-radius: 8px 8px 8px 8px;
}

/deep/.el-dialog__headerbtn {
  margin-top: 20px;
}

/deep/.el-dialog__body {
  padding: 0 40px !important;
}

/* 订单消息 */
.msg-box {
  display: flex;
  margin: 0 16px;
  cursor: pointer;
  align-items: center;

  .iconfont {
    font-size: 12px !important;
    color: #a8abbe;
    margin-right: 4px;
  }

  .msg-item {
    margin-right: 25px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }

  // 右侧左盒子
  .headersRightLeft {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;

    .text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .noRead {
      height: 11px;
      background: #ff5206;
      border-radius: 2px;
      padding: 0px 2px;
      font-size: 9px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 11px;
      color: #ffffff;
      position: absolute;
      top: -9px;
      right: -10px;
      width: 18px;
    }
  }
}

// 右侧 有token时
.headersRight {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .loginOut {
    margin-left: 8px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    cursor: pointer;
  }

  // 右侧右盒子
  .headersRights {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 34px;
      height: 34px;
      background: #0b5fa8;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

// 右侧 无token时
.toke {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    opacity: 1;
    margin-right: 12px;
    border-radius: 50%;
  }

  // 登录
  .spanL {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    cursor: pointer;
  }

  // 竖线
  div {
    width: 1px;
    height: 11px;
    background: #999999;
    margin: 0px 16px;
  }

  // 注册
  .spanR {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff4224;
    cursor: pointer;
  }
}

// 退出登录
.tuichu {
  position: absolute;
  width: 100px;
  height: 30px;
  top: 36px;
  right: 305px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}

.boxs {
  background: rgba(255, 255, 255, 0.96);
  position: fixed;
  top: 0;
  z-index: 9999;

  .nav_main {
    background: rgba(247, 247, 247, 0.96);
  }
}

.navigationSevenths {
  height: 70px;
}

/deep/ .loginDialog {
  .el-dialog {
    position: relative;
    width: 716px;
    height: 450px;
    background: linear-gradient(180deg, #0270e8 0%, #21b4f5 100%);
    border-radius: 20px 20px 20px 20px;
    opacity: 1;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 443px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 !important;
    }
  }
}
</style>
