import JSEncrypt from 'jsencrypt'
import Vue from 'vue'
import Bus from '@/api/bus'
import {
  TimeFunction
} from '@/api/TimeFunction'
const timeFunction = new TimeFunction()
import {
  WebClient
} from '@/api/webClient'
const webClient = new WebClient()
import {
  getClassSelectEnum,
  setClassSelectEnum,
  removeClassSelectEnum
} from '@/api/cookies'
import {
  Classifi
} from '@/api/classifi'
const classifi = new Classifi()
import router from '@/router'

import {
  getInfo
} from '@/api/cookies'

// 是ES6的语法，意思是将这个东西导出，你要import 引入东西，导出了才能引用
export default {
  install(Vue) {
    Vue.prototype.imgAction = function() {
      return process.env.VUE_APP_BASE_API + '/neteasy/tspResManage/uploadFile'
    }
    // 点击弹筐里的复制
    Vue.prototype.clickCopy = function(shareLink) {
      if (!shareLink) {
        this.$message.error('没有内容，不能复制哦~')
        return
      }
      var input = document.createElement('input') // 直接构建input
      input.fileName = shareLink // 设置内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      if (document.execCommand('Copy')) {
        this.$message.success('复制成功~')
      }
    }
    // 跳转
    Vue.prototype.goNext = function(val) {
      router.push(val)
    }
    // 跳转
    Vue.prototype.goBack = function(val) {
      router.back()
    }
    // 跳转(五缓存)
    Vue.prototype.goNextRep = function(val) {
      router.replace(val)
    }
    // 文件类型判断
    Vue.prototype.getFileType = function(fileName) {
      // 后缀获取
      let suffix = ''
      // 获取类型结果
      let result = ''
      try {
        const flieArr = fileName.split('.')
        suffix = flieArr[flieArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      // fileName无后缀返回 false
      if (!suffix) {
        return false
      }
      suffix = suffix.toLocaleLowerCase()
      // 图片格式
      const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // 进行图片匹配
      result = imglist.find((item) => item === suffix)
      if (result) {
        return 'image'
      }
      // 匹配txt
      const txtlist = ['txt']
      result = txtlist.find((item) => item === suffix)
      if (result) {
        return 'txt'
      }
      // 匹配 excel
      const excelist = ['xls', 'xlsx']
      result = excelist.find((item) => item === suffix)
      if (result) {
        return 'excel'
      }
      // 匹配 word
      const wordlist = ['doc', 'docx']
      result = wordlist.find((item) => item === suffix)
      if (result) {
        return 'word'
      }
      // 匹配 pdf
      const pdflist = ['pdf']
      result = pdflist.find((item) => item === suffix)
      if (result) {
        return 'pdf'
      }
      // 匹配 ppt
      const pptlist = ['ppt', 'pptx']
      result = pptlist.find((item) => item === suffix)
      if (result) {
        return 'ppt'
      }
      // 匹配 视频
      const videolist = [
        'mp4',
        'm2v',
        'mkv',
        'rmvb',
        'wmv',
        'avi',
        'flv',
        'mov',
        'm4v'
      ]
      result = videolist.find((item) => item === suffix)
      if (result) {
        return 'video'
      }
      // 匹配 音频
      const radiolist = ['mp3', 'wav', 'wmv']
      result = radiolist.find((item) => item === suffix)
      if (result) {
        return 'radio'
      }
      // 其他 文件类型
      return 'other'
    }
    // 文件类型判断
    Vue.prototype.getFileSize = function(fileName) {
      const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const STEP = 1024

      fileName = parseFloat(fileName, 10)
      for (var i = 0; i < UNITS.length; i++) {
        if (fileName < Math.pow(STEP, i)) {
          if (UNITS[i - 1]) {
            return this.format(fileName, i - 1)
          }
          return fileName + UNITS[i]
        }
      }
      return this.format(fileName, i - 1)
      /*      return (fileName / Math.pow(STEP, power)).toFixed(2) + UNITS[power]; */
      /*   format: function format(fileName, power) {

          }, */
    }
    Vue.prototype.getFilesize = function(url, callback) {
      var xhr = new XMLHttpRequest()
      xhr.open('HEAD', url, true) // Notice "HEAD" instead of "GET",//  to get only the header
      xhr.onreadystatechange = function() {
        if (this.readyState == this.DONE) {
          callback(parseInt(xhr.getResponseHeader('Content-Length')))
        }
      }
      xhr.send()
    }
    /** 跳转类型
     *   知识套餐 = 1,直播,录播,公开课,资料包,*/
    Vue.prototype.goTypeDetail = function(
      type,
      id,
      isSkip,
      title,
      IsPurchase,
      productLine,
      cardCode
    ) {
     
      if (isSkip == 0) {
        this.$message.error('课程无法观看，请联系老师')
        return
      }
      /*  */
      const sub = {
        id: id,
        cardCode: cardCode
      }
      const data = Vue.prototype.export(sub)
      switch (Number(type)) {
        case 1:
          if (productLine == 3) {
            router.push(`/VIP/VIPClassxq?id=${id}`)
          } else {
            router.push(`/typeclassxq?id=${id}`)
          }
          break
        case 2:
          router.push(`/zhiboclassxq?${data}`)
          break
        case 3:
          router.push(`/videoxq?${data}`)
          break
        case 4:
          router.push({
            name: '公开课详情',
            params: {
              id: id
            }
          })
          break
        case 5:
          router.push(`/data/dataPackageDetail?packageId=${id}`)
          break
        case 6:
          if (IsPurchase == false) {
            this.$notify({
              title: '错误',
              message: '未购买，无法学习, 请先去购买',
              type: 'error',
              duration: 2000
            })
            return
          }
          router.push(`/all/examPack?packId=${id}`)
          break
        case 7:
          if (IsPurchase == false) {
            this.$notify({
              title: '错误',
              message: '未购买，无法学习, 请先去购买',
              type: 'error',
              duration: 2000
            })
            return
          }

          router.push(`/audioCouser/index?${data}`)
          break
        case 8:
          if (IsPurchase == false) {
            this.$notify({
              title: '错误',
              message: '未购买，无法学习, 请先去购买',
              type: 'error',
              duration: 2000
            })
            return
          }

          router.push(`/imgCouser/index?${data}`)
          break
        case 10:
          if (IsPurchase == false) {
            this.$notify({
              title: '错误',
              message: '未购买，无法学习, 请先去购买',
              type: 'error',
              duration: 2000
            })
            return
          }

          router.push(`/faceCouser/index?${data}`)
          break
        case 11:
          if (IsPurchase == false) {
            this.$notify({
              title: '错误',
              message: '未购买，无法学习, 请先去购买',
              type: 'error',
              duration: 2000
            })
            return
          }
          // this.$router.push(`/markcamp/index?id=${data.id}`)
          router.push(`/markcamp/index?${data}`)
          break
        case 12:
          router.push(
            `/quesTion/examDetails?title=${title}&paperId=${id}&examType=4&type=12`
          )
          break
        case 15:
          router.push(`/quesTion/examRoom/examRoomDetail?roomId=${id}`)
          break
        case 16:
          if (productLine == 3) {
            router.push(`/VIP/VIPClassxq?id=${id}`)
          } else {
            router.push(`/typeclassxq?id=${id}`)
          }
          break
        default:
          break
      }
    }
    // 登录页跳转
    Vue.prototype.goLoginView = function(val) {
      Bus.$emit('handleLogin', val)
    }
    const publicKey =
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCqPIgJGoBvNOQ+xs4YGjMsJ4gQTt5G4t4PpQhxnMr25PwxHSeiqHUWq3yymeKk1dfd0DFWJzHLWbCcyCCQuCESnbIErRS+vD00HQPqSUgEHjo0Xfxsf3nG3AHAyGahH7kmv+54JgLTwOnsx62dvAlHvV7k9WHKah38Ao/y+mWHlQIDAQAB'
    const privateKey = ''
    // 加密
    Vue.prototype.encrypt = function(txt) {
      const encryptor = new JSEncrypt()
      encryptor.setPublicKey(publicKey) // 设置公钥
      return encryptor.encrypt(txt) // 对需要加密的数据进行加密
    }
    // 解密
    Vue.prototype.decrypt = function(txt) {
      const encryptor = new JSEncrypt()
      encryptor.setPrivateKey(privateKey)
      return encryptor.decrypt(txt)
    }
    /* 文件上传 */
    Vue.prototype.imgAction = function() {
      return process.env.VUE_APP_BASE_API + '/neteasy/tspResManage/uploadFile'
    }
    // 保存图片
    Vue.prototype.saveImg = function(url) {
      window.open(url, '_blank')
    }
    // 富文本图片解析
    Vue.prototype.ImgSizeLimit = function(html) {
      let str = html
      if (str == null) {
        return str
      }
      const index = html.indexOf('<ol')
      if (index != -1) {
        str = html.slice(0, index + 3) + " type='A'" + html.slice(index + 3)
      } else {
        str = html
      }
      var imgReg = /<img[^>]*?>/g
      str = str.replace(imgReg, (imgtag, x) => {
        const attrReg = /<img[^>]*?src[ ]*=[ ]*(['"])([^\1]*?)\1.*>/
        const match = imgtag.match(attrReg)
        if (match != null) {
          const src = match[2]
          return `<img src="${src}" style="max-width:100%;height:auto" />`
        }
        return ``
      })

      return str
    }
    // 版型对比富文本图片解析
    Vue.prototype.ImgSizeLimits = function(html) {
      let str = html
      if (str == null) {
        return str
      }
      const index = html.indexOf('<ol')
      if (index != -1) {
        str = html.slice(0, index + 3) + " type='A'" + html.slice(index + 3)
      } else {
        str = html
      }

      var imgReg = /<img[^>]*?>/g
      str = str.replace(imgReg, (imgtag, x) => {
        const attrReg = /<img[^>]*?src[ ]*=[ ]*(['"])([^\1]*?)\1.*>/
        const match = imgtag.match(attrReg)
        // 设置了宽高
        if (match[0].includes('width')) {
          const src = match[2]
          const width = match[0].match(/width="(\S*)"/)[1]
          if (width - 0 > 406) {
            // 宽大于
            return `<img src="${src}" style="max-width:100%;height:auto" />`
          } else {
            return match[0]
          }
        } else {
          // 没设置宽高
          const src = match[2]
          return `<img src="${src}" style="max-width:100%;height:auto" />`
        }
      })

      return str
    }
    // 刷题库题目过滤掉第一个p标签
    Vue.prototype.ImgSizeLimitp = function(html) {
      let str = html
      if (str == null) {
        return str
      }
      const index = html.indexOf('<ol')
      if (index != -1) {
        str = html.slice(0, index + 3) + " type='A'" + html.slice(index + 3)
      } else {
        str = html
      }
      var p = new RegExp('<p>') // 不加'g'，仅删除字符串里第一个"p"
      str = str.replace(p, '')
      var imgReg = /<img[^>]*?>/g
      str = str.replace(imgReg, (imgtag, x) => {
        const attrReg = /<img[^>]*?src[ ]*=[ ]*(['"])([^\1]*?)\1.*>/
        const match = imgtag.match(attrReg)
        // 设置了宽高
        if (match[0].includes('width')) {
          const src = match[2]
          const width = match[0].match(/width="(\S*)"/)[1]
          if (width - 0 > 406) {
            // 宽大于
            return `<img src="${src}" style="max-width:100%;height:auto" />`
          } else {
            return match[0]
          }
        } else {
          // 没设置宽高
          const src = match[2]
          return `<img src="${src}" style="max-width:100%;height:auto" />`
        }
      })

      return str
    }

    /* 项目切换 */
    /*   Vue.prototype.kindZy = async function (kind) {
        let kindZyName = "全部专业";
        await classifi.selectFieldExtendDetail(kind).then((res) => {
          if (!res.data || res.data.length == 0) {
            kindZyName = "全部专业";
          } else {
            kindZyName = res.data[0].zName ? res.data[0].zName : "全部专业";
          }
        });
        return kindZyName;
      }; */
    Vue.prototype.kindZy = async function(kind, keyWord) {
      let kindZyName = '所属专业'
      let data = {}
      await classifi.selectFieldExtendDetail(kind).then((res) => {
        if (!res.data || res.data.length == 0) {
          kindZyName = '所属专业'
        } else {
          data = res.data[0]
          kindZyName = res.data[0].zName ? res.data[0].zName : '所属专业'
        }
      })
      if (keyWord) {
        return data
      } else {
        return kindZyName
      }
    }
    /** 分类
     * 合作伙伴分类: 1 ,自考专业分类: 5 ,试卷类型: 7 ,普通专升本专业分类: 9 ,学位类别: 10 ,
     * 课程性质: 11 ,资料分类: 12 ,课件分类: 13 ,辅导资料类型: 14 ,知识套餐类型: 15 , */
    Vue.prototype.classSelectSelect = async function(type) {
      let data = []
      await classifi.getClassSelectSelect(type).then((res) => {
        data = res
      })
      return data
    }
    /** 分类枚举*/
    Vue.prototype.classSelectEnum = function(val, type) {
      let data = null
      const classfi = getClassSelectEnum(String(type))
      if (!classfi) {
        classifi.getClassSelectSelect(type).then((res) => {
          setClassSelectEnum(String(type), res)
          setTimeout(() => {
            removeClassSelectEnum(String(type)) // 情空
          }, 3 * 60 * 1000)
          for (const item of res) {
            if (val == item.fName) {
              data = item.fId
              break
            }
            if (Number(val) == Number(item.fId)) {
              data = item.fName
              break
            }
          }
        })
      } else {
        for (const item of classfi) {
          if (val == item.fName) {
            data = item.fId
            break
          }
          if (Number(val) == Number(item.fId)) {
            data = item.fName
            break
          }
        }
      }
      return data
    }
    /** 资料下载 */
    Vue.prototype.DownloadDoc = function(urlVal, downloadName) {
      const a = document.createElement('a')
      a.setAttribute('href', urlVal)
      a.setAttribute('download', downloadName)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    /* 防抖 */
    Vue.directive('throttle', {
      inserted(el, binding) {
        el.addEventListener('click', () => {
          el.style.pointerEvents = 'none'
          el.style.cursor = 'wait'
          if (!el.disabled) {
            setTimeout(() => {
              el.style.pointerEvents = 'auto'
              el.style.cursor = 'pointer'
            }, binding.fileName || 2000)
          }
        })
      }
    })
    /* 时间计算差值 */
    Vue.prototype.dayLong = async function(begintime) {
      let time = begintime
      await timeFunction.DateDiffTime(begintime).then((res) => {
        if (res.days == 0) {
          if (res.hours == 0) {
            if (res.minutes == 0) {
              time = res.seconds + '秒前'
            } else {
              time = res.minutes + '分钟前'
            }
          } else {
            time = res.hours + '小时前'
          }
        } else if (res.days == 1 || (res.days > 1 && res.days < 7)) {
          time = '一天前'
        } else if (res.days == 7 || (res.days > 7 && res.days < 30)) {
          time = '一周前'
        } else if (res.days == 30 || res.days > 30) {
          time = '一个月前'
        }
      })
      return time
    }
    /* 导出传参 */
    Vue.prototype.export = function(sub) {
      const params = webClient.combin({}, sub)
      let data = ''
      for (var d in params) {
        if (data == '') {
          data = data + `${d}=${params[d]}`
        } else {
          data = data + `&${d}=${params[d]}`
        }
      }
      return data
    }
    /* 跳转试卷答题页 */
    Vue.prototype.goExam = function(
      title,
      paperId,
      examType,
      type,
      IsPurchase,
      seeNum,
      courseId,
      showId,
      classId,
      cardCode
    ) {
      const userInfo = getInfo()
      if (!userInfo) {
        Bus.$emit('handleLogin', false)
        return
      }
      if (!IsPurchase && !seeNum) {
        this.$notify({
          title: '错误',
          message: '未购买，无法答题, 请先去购买',
          type: 'error',
          duration: 2000
        })
        return
      }
      const sub = {
        title: title,
        paperId: paperId,
        examType: examType,
        type: type,
        IsPurchase: IsPurchase,
        seeNum: seeNum,
        courseId: courseId,
        showId: showId,
        classId: classId,
        cardCode
      }
      const data = Vue.prototype.export(sub)
      window.open(`/quesTion/examDetails?${data}`, '_blank')
    }
    /* 跳转试卷答题页 */
    Vue.prototype.goExams = function(
      title,
      paperId,
      examType,
      type,
      IsPurchase,
      seeNum,
      courseId,
      showId,
      classId,
      cardCode
    ) {
      const userInfo = getInfo()
      if (!userInfo) {
        Bus.$emit('handleLogin', false)
        return
      }

      const sub = {
        title: title,
        paperId: paperId,
        examType: examType,
        type: type,
        IsPurchase: IsPurchase,
        seeNum: seeNum,
        courseId: courseId,
        showId: showId,
        classId: classId,
        cardCode
      }
      const data = Vue.prototype.export(sub)
      window.open(`/quesTion/examDetailss?${data}`, '_blank')
    }
        Vue.prototype.goExamPartner= function(
      title,
      paperId,
      examType,
      type,
      IsPurchase,
      seeNum,
      courseId,
      showId,
      classId,
      cardCode
    ) {
      const userInfo = getInfo()
      if (!userInfo) {
        Bus.$emit('handleLogin', false)
        return
      }
      if (!IsPurchase && !seeNum) {
        this.$notify({
          title: '错误',
          message: '未购买，无法答题, 请先去购买',
          type: 'error',
          duration: 2000
        })
        return
      }
      const sub = {
        title: title,
        paperId: paperId,
        examType: examType,
        type: type,
        IsPurchase: IsPurchase,
        seeNum: seeNum,
        courseId: courseId,
        showId: showId,
        classId: classId,
        cardCode
      }
      const data = Vue.prototype.export(sub)
      window.open(`/quesTion/examDetails?${data}`, '_blank')
    }
    /* 跳转试卷答题页 */
    Vue.prototype.getExplorer = function() {
      var ua = navigator.userAgent.toLocaleLowerCase()
      var browserType = null
      if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
        browserType = 'IE'
      } else if (ua.match(/firefox/) != null) {
        browserType = '火狐'
      } else if (ua.match(/ubrowser/) != null) {
        browserType = 'UC'
      } else if (ua.match(/opera/) != null) {
        browserType = '欧朋'
      } else if (ua.match(/bidubrowser/) != null) {
        browserType = '百度'
      } else if (ua.match(/metasr/) != null) {
        browserType = '搜狗'
      } else if (
        ua.match(/tencenttraveler/) != null ||
        ua.match(/qqbrowse/) != null
      ) {
        browserType = 'QQ'
      } else if (ua.match(/maxthon/) != null) {
        browserType = '遨游'
      } else if (ua.match(/chrome/) != null) {
        var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')

        function _mime(option, value) {
          var mimeTypes = navigator.mimeTypes
          for (var mt in mimeTypes) {
            if (mimeTypes[mt][option] == value) {
              return true
            }
          }
          return false
        }
        if (is360) {
          browserType = '360'
        } else {
          browserType = 'Chrome'
        }

        /*  if (window.navigator.userActivation) {
           browserType = '360';
           var is360js = _mime("type", "application/vnd.chromium.remoting-viewer");

           function _mime(option, value) {
             var mimeTypes = navigator.mimeTypes;
             for (var mt in mimeTypes) {
               if (mimeTypes[mt][option] == value) {
                 return true;
               }
             }
             return false;
           }
           if (is360js) {
             browserType = '360兼容模式';
           } else {
             browserType = '360急速模式';
           }
         } else {
           browserType = 'Chrome';
         } */
      } else if (ua.match(/safari/) != null) {
        browserType = 'Safari'
      }
      return browserType
    }
    Vue.prototype.getIeVersion = function() {
      const IEMode = document.documentMode
      const rMsie = /(msie\s|trident.*rv:)([\w.]+)/
      const ma = window.navigator.userAgent.toLowerCase()
      const match = rMsie.exec(ma)
      try {
        return match[2]
      } catch (e) {
        return IEMode
      }
    }
    Vue.prototype.getOperaVersion = function(userAgent) {
      try {
        if (window.opera) {
          return userAgent.match(/opera.([\d.]+)/)[1]
        } else if (userAgent.indexOf('opr') > 0) {
          return userAgent.match(/opr\/([\d.]+)/)[1]
        }
      } catch (e) {
        return 0
      }
    }
    Vue.prototype.validate360 = function(option, value) {
      const mimeTypes = window.navigator.mimeTypes
      for (const mt in mimeTypes) {
        // console.log(mimeTypes[mt][option])
        if (mimeTypes[mt][option] == value) {
          return true
        }
      }
      return false
    }
    /* =======================聚学U=================================== */
    // 登录页跳转
    Vue.prototype.juxueHand = function(val) {
      Bus.$emit('juxueShoole', val)
    }
    Vue.prototype.Base64 = {
      // 加密
      encode(str) {
        if (str === undefined || str === '' || str === null) {
          return str
        }
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1)
          }))
      },
      // 解密
      decode(str) {
        if (str === undefined || str === '' || str === null) {
          return str
        }
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
      }
    }
    // 保存图片
    Vue.prototype.windowOpen = function(url) {
      window.open(url, '_blank')
    }
    // 做题时间 时分秒
    Vue.prototype.formatAnswerTime = function(value) {
      const result = parseInt(value)
      const h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      const m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      const s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))

      let res = ''
      if (h !== '00') res += `${h}:`
      res += `${m}:`
      res += `${s}`
      return res
    }
  }
}
